/* eslint-disable max-len */
import { BaseEitherError, TJSONParseOperationErrors } from '@uniqkey-frontend/shared-app';

export class DecryptTrustedActionPayloadCommonDecryptionError extends BaseEitherError {}

export class DecryptTrustedActionPayloadInitialDecryptionError extends BaseEitherError {}

export class DecryptTrustedActionPayloadOrganizationPrivateDecryptionError extends BaseEitherError {}

export type TDecryptTrustedActionPayloadOperationErrors = DecryptTrustedActionPayloadCommonDecryptionError
  | DecryptTrustedActionPayloadInitialDecryptionError
  | DecryptTrustedActionPayloadOrganizationPrivateDecryptionError
  | TJSONParseOperationErrors;
