import { getHoursAndMinutes, TFunction } from '@uniqkey-frontend/shared-app';
import {
  GetIpGroupRestrictionByIdResponse,
  GetTimeOfDayGroupRestrictionByIdResponse,
  GetGeolocationGroupRestrictionByIdResponse,
  RestrictionType,
} from '@uniqkey-backend-organization-web/api-client';
import { RestrictionDayAsFlagEnum, RestrictionDayNameEnum } from '../../enums/RestrictionDayEnum';
import { TRestriction } from '../../hooks/useGroupRestrictionsAPI/interfaces';

const RESTRICTIONS_DAYS_LONG_TRANSLATION_KEYS = {
  [RestrictionDayNameEnum.Monday]: 'restrictions.days.long.monday',
  [RestrictionDayNameEnum.Tuesday]: 'restrictions.days.long.tuesday',
  [RestrictionDayNameEnum.Wednesday]: 'restrictions.days.long.wednesday',
  [RestrictionDayNameEnum.Thursday]: 'restrictions.days.long.thursday',
  [RestrictionDayNameEnum.Friday]: 'restrictions.days.long.friday',
  [RestrictionDayNameEnum.Saturday]: 'restrictions.days.long.saturday',
  [RestrictionDayNameEnum.Sunday]: 'restrictions.days.long.sunday',
};

export const isRestrictionDayChecked = (
  days: number,
  day: RestrictionDayAsFlagEnum,
// eslint-disable-next-line no-bitwise
): boolean => (days & day) > 0;

export const buildRestrictionDaysAsFlags = (
  days: RestrictionDayAsFlagEnum[],
): number => days.reduce(
// eslint-disable-next-line no-bitwise
  (daysAsNumber, day) => daysAsNumber | day,
  0,
);

const DAYS_FLAGS_AS_ARRAY = [
  RestrictionDayAsFlagEnum.Monday,
  RestrictionDayAsFlagEnum.Tuesday,
  RestrictionDayAsFlagEnum.Wednesday,
  RestrictionDayAsFlagEnum.Thursday,
  RestrictionDayAsFlagEnum.Friday,
  RestrictionDayAsFlagEnum.Saturday,
  RestrictionDayAsFlagEnum.Sunday,
];

const ALL_DAYS_ARE_CHECKED = buildRestrictionDaysAsFlags(DAYS_FLAGS_AS_ARRAY);

export const parseTimeRestriction = (days: number): RestrictionDayAsFlagEnum[] => {
  if (days === 0) return [];
  if (days === ALL_DAYS_ARE_CHECKED) return [...DAYS_FLAGS_AS_ARRAY];
  const checkedDays: RestrictionDayAsFlagEnum[] = [];
  DAYS_FLAGS_AS_ARRAY.forEach((day) => {
    if (isRestrictionDayChecked(days, day)) {
      checkedDays.push(day);
    }
  });
  return checkedDays;
};

const buildTimeTranslations = (t: TFunction, days: number): string => {
  if (days === 0) {
    return '';
  }
  if (days === ALL_DAYS_ARE_CHECKED) {
    return t('restrictions.allDaysOfTheWeek');
  }
  const checkedTranslatedDays: string[] = [];
  DAYS_FLAGS_AS_ARRAY.forEach((day) => {
    if (isRestrictionDayChecked(days, day)) {
      const checkedDay = RestrictionDayNameEnum[
        RestrictionDayAsFlagEnum[day] as keyof typeof RestrictionDayNameEnum
      ];
      checkedTranslatedDays.push(t(RESTRICTIONS_DAYS_LONG_TRANSLATION_KEYS[checkedDay]));
    }
  });
  return checkedTranslatedDays.join(', ');
};

export const generateRestrictionName = (t: TFunction, restriction: TRestriction): string => {
  const { restrictionType } = restriction;
  if (restrictionType === RestrictionType.IpAddress) {
    const { ipAddress } = restriction as GetIpGroupRestrictionByIdResponse;
    return t('restrictions.restrictedTo', { suffix: ipAddress });
  }
  if (restrictionType === RestrictionType.Time) {
    const { dayOfWeek } = restriction as GetTimeOfDayGroupRestrictionByIdResponse;
    const dayOfWeekTranslations = buildTimeTranslations(t, dayOfWeek);
    return t('restrictions.restrictedTo', { suffix: dayOfWeekTranslations });
  }
  if (restrictionType === RestrictionType.Location) {
    const { locationAddress } = restriction as GetGeolocationGroupRestrictionByIdResponse;
    return t('restrictions.restrictedTo', {
      suffix: locationAddress,
    });
  }
  throw new Error(`Type ${restrictionType} not supported.`);
};

export const generateRestrictionDescription = (t: TFunction, restriction: TRestriction): string => {
  const { restrictionType } = restriction;
  if (restrictionType === RestrictionType.IpAddress) {
    const { description } = restriction as GetIpGroupRestrictionByIdResponse;
    return description;
  }
  if (restrictionType === RestrictionType.Time) {
    const {
      allowedFromTime: from,
      allowedToTime: to,
      offsetName: timezoneName,
    } = restriction as GetTimeOfDayGroupRestrictionByIdResponse;
    return t('restrictions.descriptions.timeBased', {
      from: getHoursAndMinutes(from),
      to: getHoursAndMinutes(to),
      timezoneName,
    });
  }
  if (restrictionType === RestrictionType.Location) {
    const { locationAddress, radius } = restriction as GetGeolocationGroupRestrictionByIdResponse;
    return t('restrictions.descriptions.location', {
      locationAddress,
      radius,
    });
  }
  throw new Error(`Type ${restrictionType} not supported.`);
};

export const convertTimeToString = (val: number) => {
  if (val === 24) return '23:59:59';
  if (val === 0) return '00:00:00';
  const hours = Math.floor(val).toString().padStart(2, '0');
  const minutes = Number.isInteger(val) ? '00' : '30';
  return `${hours}:${minutes}:00`;
};

export const convertStringToTime = (value: string): number => {
  if (value.includes('23:59:59')) return 24;
  const processedValue = Number(
    (getHoursAndMinutes(value) as string).replace(':30', '.5').replace(':00', ''),
  );
  if (Number.isNaN(processedValue)) throw new Error('Incorrect \'value\' provided');
  return processedValue;
};
