import { useCallback, useState, memo } from 'react';
import {
  useTranslations,
  NavigationPanel,
  NavigationItem,
  NavigationSubHeader,
  SpeedIcon,
  UsersIcon,
  GroupsIcon,
  SettingsIcon,
  KeyIcon,
  LockIcon,
  ProfileIcon,
  LogoutIcon,
  FlagIcon,
  Box,
  ScrollableBox,
  Divider,
  AB2,
  type INavigationItemProps,
  LeftDoubleArrowIcon,
  RightDoubleArrowIcon,
  NotificationsIcon,
} from '@uniqkey-frontend/shared-app';
import {
  useLocation,
  useNavigate,
  useMatch,
} from 'react-router-dom';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import { AllSecuredDataPageTabEnum } from '../../../pages/AllSecuredDataPage';
import { AuditLogsModuleTabEnum } from '../../../pages/AuditLogsPage/components/AuditLogsModule';
import { SettingsModuleTabEnum } from '../../../pages/SettingsPage/components/SettingsModule';
import { RequestsModuleTabEnum } from '../../../pages/RequestsPage/components/RequestsModule';
import { publishTablePreservationReset } from '../../../helpers/tablePreservation/reset';
import OrganizationSelector from './components/OrganizationSelector';
import TrustedPortalRow from './components/TrustedPortalRow';
import LogoutModal from './components/LogoutModal';

const PATHS_WITH_TABS = {
  AllSecuredData: `${PageRouteEnum.AllSecuredData}/${AllSecuredDataPageTabEnum.Logins}`,
  Audit: `${PageRouteEnum.Audit}/${AuditLogsModuleTabEnum.AuditLogs}`,
  Requests: `${PageRouteEnum.Requests}/${RequestsModuleTabEnum.AccountRecovery}`,
  Settings: `${PageRouteEnum.Settings}/${SettingsModuleTabEnum.Details}`,
};

const LIST_ITEM_TOP_MARGIN = 8;
const LIST_ITEM_ELEMENT_HEIGHT = 48;

const MAIN_MENU_ITEMS_SX = {
  minHeight: (LIST_ITEM_TOP_MARGIN + LIST_ITEM_ELEMENT_HEIGHT) * 2.5,
};

const DIVIDER_SX = { borderColor: AB2 };

interface ISideProps {
  isMenuCollapsed: boolean;
  onExpandCollapse: () => void;
}

const Side = (props: ISideProps) => {
  const { isMenuCollapsed, onExpandCollapse } = props;
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const handleLogoutModalOpen = useCallback(() => setIsLogoutModalOpen(true), []);
  const handleLogoutModalClose = useCallback(() => setIsLogoutModalOpen(false), []);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslations();
  const isEmployeePage = !!useMatch(PageRouteEnum.Employee);
  const isGroupPage = !!useMatch(PageRouteEnum.Group);
  const isServicePage = !!useMatch(PageRouteEnum.Service);
  const isAllSecuredDataPage = !!useMatch(`${PageRouteEnum.AllSecuredData}/*`);
  const isAuditLogsPage = !!useMatch(`${PageRouteEnum.Audit}/*`);
  const isRequestsPage = !!useMatch(`${PageRouteEnum.Requests}/*`);
  const isSettingsPage = !!useMatch(`${PageRouteEnum.Settings}/*`);

  // const navigation = [
  //   {
  //     name: <Typography>{t('navigation.dashboard')}</Typography>,
  //     path: '/dashboard',
  //   },
  //   {
  //     name: <Typography>{t('navigation.onboarding')}</Typography>,
  //     path: '/onboarding',
  //   },
  //   {
  //     name: <Typography>{t('navigation.employees')}</Typography>,
  //     path: '/employees',
  //   },
  //   {
  //     name: <Typography>{t('navigation.groups')}</Typography>,
  //     path: '/groups',
  //   },
  //   {
  //     name: <Typography>{t('navigation.reports')}</Typography>,
  //     path: '/reports',
  //   },
  //   {
  //     name: <Typography>{t('navigation.emails')}</Typography>,
  //     path: '/emails',
  //   },
  //   {
  //     name: <Typography>{t('navigation.services')}</Typography>,
  //     path: '/services',
  //   },
  //   {
  //     name: <Typography>{t('navigation.secureData')}</Typography>,
  //     path: '/secure-data',
  //   },
  //   {
  //     name: <Typography>{t('navigation.settings')}</Typography>,
  //     path: '/settings',
  //   },
  //   {
  //     name: <Typography>{t('navigation.profile')}</Typography>,
  //     path: '/profile',
  //   },
  // ];

  const handleMenuItemClick = useCallback<INavigationItemProps['onClick']>((nextPathname) => {
    if (pathname !== nextPathname) {
      navigate(nextPathname as string);
      return;
    }
    navigate(nextPathname, { replace: true });
    publishTablePreservationReset();
  }, [pathname, navigate]);

  return (
    <NavigationPanel container item xs showLogoWithoutText={isMenuCollapsed}>
      <Box>
        {isMenuCollapsed
          ? <Box mt={3} />
          : <NavigationSubHeader text={t('navigation.organization')} />}
      </Box>
      <OrganizationSelector />
      <TrustedPortalRow isMenuCollapsed={!isMenuCollapsed} />
      <Box my={1}>
        <Divider sx={DIVIDER_SX} />
      </Box>
      <ScrollableBox sx={MAIN_MENU_ITEMS_SX}>
        <NavigationItem
          pathname={PageRouteEnum.Dashboard}
          isActive={pathname === PageRouteEnum.Dashboard}
          text={t('navigation.dashboard')}
          icon={<SpeedIcon />}
          onClick={handleMenuItemClick}
          showText={!isMenuCollapsed}
        />
        <NavigationItem
          pathname={PageRouteEnum.Employees}
          isActive={isEmployeePage || pathname === PageRouteEnum.Employees}
          text={t('navigation.employees')}
          icon={<UsersIcon />}
          onClick={handleMenuItemClick}
          showText={!isMenuCollapsed}
        />
        <NavigationItem
          pathname={PageRouteEnum.Groups}
          isActive={isGroupPage || pathname === PageRouteEnum.Groups}
          text={t('navigation.groups')}
          icon={<GroupsIcon />}
          onClick={handleMenuItemClick}
          showText={!isMenuCollapsed}
        />
        <NavigationItem
          pathname={PageRouteEnum.Services}
          isActive={isServicePage || pathname === PageRouteEnum.Services}
          text={t('navigation.services')}
          icon={<KeyIcon />}
          onClick={handleMenuItemClick}
          showText={!isMenuCollapsed}
        />
        <NavigationItem
          pathname={PATHS_WITH_TABS.AllSecuredData}
          isActive={isAllSecuredDataPage}
          text={t('navigation.allSecuredData')}
          icon={<LockIcon />}
          onClick={handleMenuItemClick}
          showText={!isMenuCollapsed}
        />
        <NavigationItem
          pathname={PATHS_WITH_TABS.Audit}
          isActive={isAuditLogsPage}
          text={t('navigation.auditLogs')}
          icon={<FlagIcon />}
          onClick={handleMenuItemClick}
          showText={!isMenuCollapsed}
        />
        <NavigationItem
          pathname={PATHS_WITH_TABS.Requests}
          isActive={isRequestsPage}
          text={t('navigation.requests')}
          icon={<NotificationsIcon />}
          onClick={handleMenuItemClick}
          showText={!isMenuCollapsed}
        />
        <NavigationItem
          pathname={PATHS_WITH_TABS.Settings}
          isActive={isSettingsPage}
          text={t('navigation.settings')}
          icon={<SettingsIcon />}
          onClick={handleMenuItemClick}
          showText={!isMenuCollapsed}
        />
      </ScrollableBox>
      <Box>
        {isMenuCollapsed
          ? <Box mt={3} />
          : <NavigationSubHeader text={t('navigation.account')} />}
        <NavigationItem
          pathname={PageRouteEnum.Profile}
          isActive={pathname === PageRouteEnum.Profile}
          text={t('navigation.profile')}
          icon={<ProfileIcon />}
          onClick={handleMenuItemClick}
          showText={!isMenuCollapsed}
        />
        <NavigationItem
          text={t('navigation.logout')}
          icon={<LogoutIcon />}
          onClick={handleLogoutModalOpen}
          showText={!isMenuCollapsed}
        />
      </Box>
      <Box my={1}>
        <Divider sx={DIVIDER_SX} />
      </Box>
      <Box>
        <NavigationItem
          text=""
          icon={isMenuCollapsed ? <RightDoubleArrowIcon /> : <LeftDoubleArrowIcon />}
          onClick={onExpandCollapse}
          showText={!isMenuCollapsed}
        />
      </Box>
      {isLogoutModalOpen && (
        <LogoutModal
          isOpen={isLogoutModalOpen}
          onClose={handleLogoutModalClose}
        />
      )}
    </NavigationPanel>
  );
};

export default memo(Side);
