import { useCallback, memo, useState } from 'react';
import {
  GetGroupByIdV2Response, GetGroupsV2ResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import { useSnackbar, useTranslations } from '@uniqkey-frontend/shared-app';
import EntitySelectorModal, {
  TEntitySelectorModalOnSubmit,
} from '../../../../components/EntitySelectorModal';
import useEmployeeGroupsAPI from '../../../../hooks/useEmployeeGroupsAPI';
import useEmployeeAccountsToGroupsAPI from '../../../../hooks/useEmployeeAccountsToGroupsAPI';
import { logException } from '../../../../services/sentryService';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';
import { getTranslationKeyByError } from '../../../../helpers/errorService';

interface IGroupsSelectorModalProps {
    isOpen: boolean;
    onClose: () => void;
    employeeAccountsIds: string[];
    onSubmit?: () => void;
}

interface IParsedEntity {
  id: GetGroupByIdV2Response['groupId'];
  label: GetGroupByIdV2Response['name'];
}

const GroupsSelectorModal = (props: IGroupsSelectorModalProps) => {
  const {
    isOpen, onClose, employeeAccountsIds, onSubmit,
  } = props;
  const [isGroupsSelectorModalLoading, setIsGroupsSelectorModalLoading] = useState(false);
  const [loadedGroupsCount, setLoadedGroupsCount] = useState<number>(0);
  const [totalLoadedGroupsCount, setTotalLoadedGroupsCount] = useState<number>(0);
  const [response, setResponse] = useState<GetGroupsV2ResponseModel[]>([]);

  const { t } = useTranslations();
  const { showSuccess, showError } = useSnackbar();
  const { getGroups } = useEmployeeGroupsAPI();
  const { inviteBulkToGroup } = useEmployeeAccountsToGroupsAPI();

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const handleAutocompleteRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data, total } = await getGroups({ page: 1, pageLength: 200, searchQuery });
      setLoadedGroupsCount(data.length);
      setTotalLoadedGroupsCount(total);
      setResponse(data);
      return data;
    } catch (e) {
      setLoadedGroupsCount(0);
      setTotalLoadedGroupsCount(0);
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupsSelectorModal/handleAutocompleteRequest exception',
      });
      return [];
    }
  }, [getGroups, showError, t]);

  const handleSubmitEntitySelectorModal = useCallback<
    TEntitySelectorModalOnSubmit<IParsedEntity[]>
  >(async (value) => {
    try {
      const groupsIds = value.map((el) => el.id);
      setIsGroupsSelectorModalLoading(true);
      await inviteBulkToGroup({ employeeAccountsIds, groupsIds });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.membersAddedToGroup', {
            count: employeeAccountsIds.length,
          }),
        });
      } else {
        showSuccess({
          text: t('employeesPage.addingEmployeesToGroup', { count: employeeAccountsIds.length }),
        });
      }
      if (onSubmit) {
        onSubmit();
      }
      setIsGroupsSelectorModalLoading(false);
      onClose();
    } catch (e) {
      showError({ text: t(getTranslationKeyByError(e)) });
      setIsGroupsSelectorModalLoading(false);
      logException(e, {
        message: 'GroupsSelectorModal/handleSubmitEntitySelectorModal exception',
      });
    }
  }, [
    inviteBulkToGroup,
    employeeAccountsIds,
    isTrustedPortalEnabled,
    onSubmit,
    onClose,
    showSuccess,
    showError,
    t,
  ]);

  const handleResponseEntitySelectorModal = useCallback(
    (groupsToParse: GetGroupByIdV2Response[]) => groupsToParse.map((group) => ({
      id: group.groupId,
      label: group.name,
    })),
    [],
  );

  return (
    <EntitySelectorModal
      data={response}
      dataKey="groupId"
      isOpen={isOpen}
      title={t('employeesPage.addToGroup.title')}
      placeholder={t('employeesPage.selectGroup')}
      onSubmit={handleSubmitEntitySelectorModal}
      request={handleAutocompleteRequest}
      responseParser={handleResponseEntitySelectorModal}
      onClose={onClose}
      isLoading={isGroupsSelectorModalLoading}
      loadedOptionsCount={loadedGroupsCount}
      totalLoadedOptionsCount={totalLoadedGroupsCount}
    />
  );
};

export default memo(GroupsSelectorModal);
